import React, { useState, useContext, useCallback } from 'react';

import styled from "styled-components";

import { RoundedDiv } from 'resources/global-styles';
import { baseConfigs, pathVal } from 'resources/constants';
import { brokerAuth, sessionId } from 'resources/utils';
import { useFetch } from 'resources/hooks';
import { QuoteContext } from '_Broker/pages/Quote';
import { colours } from 'resources/global-styles';


import Warning from '_Broker/structure/Warning';
import Loading from '_Broker/structure/Loading';
import Appetite from './Appetite';
import Loan from './Loan';
import AllProducts from './AllProducts';

const paragraphStyle = {
  color: colours.sub_text_copy,
};

const H3 = styled.h3`
  font-size: 20px;
  color: ${colours.darkGrey};
  display: block;
  width: 100%;
  margin-block-end: 0;
`;

const H4 = styled.h4`
  font-size: 16px;
  font-weight: normal;
  color: ${colours.sub_text_copy};
  width: 100%;
  margin-block-end: 0;
`;

const summaryDescription = [
  ' ',
  'An application based on the information you\'ve provided so far would be likely to meet our lending conditions.',
  'An application based on the information you\'ve provided would be slightly outside our normal lending conditions. You can still proceed, we would need to do some further checks.',
  'Unfortunately, an application based on the information you\'ve provided is unlikely to meet our lending conditions.',
]

const Summary = ({ handleStateChange, handleStageChange }) => {
  const context = useContext(QuoteContext);
  const [path, setPath] = useState(pathVal.loading);
  
  const getValue = (field) => {
    if (context[field]) {
      if (context[field].value === null) return null;
      if (context[field].value === 'yes') return true;
      if (context[field].value === 'no') return false;
      if (!isNaN(context[field].value)) return Number(context[field].value);
      return context[field].value;
    }
    return null;
  }

  const { loading, error, data } = useFetch('Quotes', true, {
    amount: getValue('loan.amount'),
    term: getValue('loan.term'),
    sectorId: getValue('business.sector.consolidated'),
    securityCoverage: getValue('loan.secured') === 'none' ? 0 : 100,
    webSessionId: sessionId(),
    brokerFirmIdentifier: brokerAuth(),
    loanPurpose: getValue('loan.purpose'),
    loanToBeSecuredVia: getValue('loan.secured'),
    isPropertyOwnedByTheBusiness: getValue('loan.secured.property_owned'),
    valueOfPropertyToBeOfferedAsSecurity: getValue('loan.secured.property_value'),
    purchasePriceOfPropertyToBeOfferedAsSecurity: getValue('loan.secured.property_purchase'),
    assetToBeOfferedAsSecurity: getValue('loan.secured.other_asset'),
    lengthOfTrading: getValue('business.trading'),
    clientLegalBusinessEntity: getValue('business.entity'),
    isClientFinancialInformationKnown: getValue('business.financial'),
    ebitda: getValue('business.financial.ebitda'),
    totalDirectorsRemuneration: getValue('business.financial.director_remuneration'),
    existingDebt: getValue('business.financial.existing_debt'),
    grossRent: getValue('business.financial.gross_rent'),
  }, null, [getValue('loan.amount'), getValue('loan.term')]);

  const handlePath = useCallback((newPath) => setPath(newPath), [path] );

  let maxCost = 0;
  let interestRateSensitised = 0; // + 1.7
  if (data) {
    maxCost = data.relevantProducts.reduce((max, product) => Math.max(product.monthlyCost, max), 0);
  }

  if (data) {
    const isRealEstateInvestment = [103, 104].includes(getValue('business.sector.consolidated'));
    // console.log('isRealEstateInvestment         :', isRealEstateInvestment);
    // const UIRRealEstateInvestiment = isRealEstateInvestment ? 5 : 1.7;
    // const UIRRealEstateInvestiment = 5;
    const UIRRealEstateInvestiment = baseConfigs.realEstateUIR;
    // console.log('UIRRealEstateInvestiment       :', UIRRealEstateInvestiment);

    const product = data.relevantProducts.filter((p) => p.name === 'Variable rate loan (includes Bank base rate)');
    if(product && product.length) {
      // console.log("olaf-386: Variable rate is      : ", product[0].rate);
      // console.log("olaf-386: Base     rate is      : ", product[0].baseRate);

      interestRateSensitised = product[0].rate + UIRRealEstateInvestiment - product[0].baseRate;
      // interestRateSensitised = product[0].rate + 1.7;
    }
  }

  return (
    <>
      <p  style={paragraphStyle}>{summaryDescription[path]}</p>
      <Loan handleChange={handleStateChange} />

      {error && <RoundedDiv>
        <p>An error occured while retrieving the products. The server returned:</p>
        <Warning>{error}</Warning>
      </RoundedDiv>}
      
      {loading && <RoundedDiv><Loading /></RoundedDiv>}
      
      {data && (
        <>
          {!loading && data && <Appetite 
            maxCost={maxCost} 
            quoteId={data.quoteId} 
            handlePath={handlePath} 
            interestRateSensitised={interestRateSensitised} 
          />}
          {path < pathVal.red && 
            <>
              <p style={paragraphStyle}>
                <H3>Please note that any products with Early Repayment Charges are not available for customers who are based in Northern Ireland or who have a business relationship manager with us.</H3>
              </p>
              <AllProducts handleStateChange={handleStateChange} handleStageChange={handleStageChange} products={data.relevantProducts} quote={data.quoteId} />
              <Warning>
                {getValue('loan.term') > 10 &&
                  <p>Appetite is based on the 10-year fixed-rate product</p>
                }
                <p>The quotes are for indicative purposes ONLY and do not constitute an offer. A full credit and affordability check will be carried out and your actual quote may vary.</p>
                Security fees may apply and repayments may vary depending on loan start date or repayment profile.
              </Warning>
            </>
          } 
        </>
      )}
    </>
  )
}

export default Summary;
