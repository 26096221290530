import React, { useContext } from 'react';
import styled from 'styled-components';

import { colours } from 'resources/global-styles';
import { brokerAuth } from 'resources/utils';
import { useFetch } from 'resources/hooks';
import { QuoteContext } from '_Broker/pages/Quote';

import Product from '../Summary/AllProducts/Product/Product';
import StarRating from './StarRating';
import Loading from '_Broker/structure/Loading';
import { trackApplicationComplete } from 'resources/analyticsHelper';


import { bucket } from '../../pages/Quote'


const H2 = styled.h2 `
  font-weight: bold;
  font-size: 26px;
`
const Heading = styled.div`
  font-size: 22px;
`
const Separator = styled.div`
  height: 1px;
  background-color: ${colours.mediumGrey};
  margin: 50px 0;
`

const ConfirmRefer = () => {
  const context = useContext(QuoteContext);
// Survey link - no longer required
// const [loadedUserZoom, errorUserZoom] = useScript('https://cdn4.userzoom.com/trueintent/js/uz_til.js?cuid=FC742306F6D9E311BEDA0022196C4538');

  const getValue = (field) => {
    if (context[field]) {
      if (field === 'refer.broker.phone' || 'refer.client.phone') return String(context[field].value);
      if (context[field].value === 'yes') return true;
      if (context[field].value === 'no') return false;
      if (!isNaN(context[field].value)) return Number(context[field].value);
      return context[field].value;
    }
    return null;
  }

  const completeFetch = useFetch(`Referrals/${getValue('id.referral')}/complete`, true, {
    brokerFirmIdentifier: brokerAuth(),
    brokerFirstName: getValue('refer.broker.forename'),
    brokerLastName: getValue('refer.broker.surname'),
    brokerEmail: getValue('refer.broker.email'),
    brokerPhoneNumber: getValue('refer.broker.phone'),
    brokerCompanyName: getValue('refer.broker.company'),
    isBrokerMainPointOfContact: getValue('refer.broker.contact') === 'broker',
    clientFirstName: getValue('refer.client.forename'),
    clientLastName: getValue('refer.client.surname'),
    clientEmail: getValue('refer.client.email'),
    clientPhoneNumber: getValue('refer.client.phone'),
    clientRegisteredBusinessName: getValue('refer.client.company'),
    clientAddressLine1: getValue('refer.client.address1'),
    clientAddressLine2: getValue('refer.client.address2'),
    clientAddressTown: getValue('refer.client.town'),
    clientAddressPostcode: getValue('refer.client.postcode'),
    additionalInformation: getValue('refer.additional.information'),
    interestCoverRatio: bucket.interestCoverRatio,
    nriDscRatio: bucket.nriDscRatio,
  });

/*  if (loadedUserZoom) {
    window.uz_til.g_preview = true;
    window.uz_til.g_imp_segmentid = '1';
    window.uz_til.g_startPath = '/paap/';
    window.uz_til.start({ 'ID' :'219f9ad9121cea11aa5ec9768ad49726', 'SID' : '2F9D9AD9121CEA11AA5EC9768AD49726'});
  } */

  if (completeFetch.data) {
    trackApplicationComplete(completeFetch.data.referenceNumber);
  }


  return (
    <>
      {completeFetch.data && context && <Heading>
        <p>Your indicative quote has been emailed to <strong>{context['refer.broker.email'].value}</strong></p>
        <p>The reference number is <strong>{completeFetch.data.referenceNumber}</strong></p>
      </Heading>}
      {completeFetch.loading && <Heading><Loading /></Heading>}
      {completeFetch.error && <Heading>There has been a problem contacting the NatWest servers</Heading>}
      <p>This is still an indicative quote, subject to satisfactory additional checks on the information you have provided, and as such may be subject to change once we confirm the full details of your request.</p>
      <H2>Your indicative quote</H2>
      <Product loanAmount={getValue('loan.amount')} loanTerm={getValue('loan.term')} product={{
        name: getValue('id.product.name'),
        rate: getValue('id.product.rate'),
        monthlyCost: getValue('id.product.monthlyCost'),
        arrangementFee: getValue('id.product.arrangementFee'),
        earlyRepaymentFee: getValue('id.product.earlyRepaymentFee')
      }} />
      <H2>What happens next</H2>
      <p>We'll review the details of your loan and assign a relationship manager to you.</p>
      <p>They'll aim to get in touch within the next 24 hours.</p>
      <Separator />
      <StarRating referral={getValue('id.referral')} broker={{
        forename: getValue('refer.broker.forename'),
        surname: getValue('refer.broker.surname'),
        company: getValue('refer.broker.company'),
        email: getValue('refer.broker.email'),
        phone: getValue('refer.broker.phone'),
      }} />
      <Separator />
    </>
  )
}

export default ConfirmRefer;
