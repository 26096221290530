import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { trackButtonClick } from 'resources/analyticsHelper';

import { colours, RoundedDiv, TextLink } from 'resources/global-styles';
import { Button, media } from 'resources/global-styles';
import { formatter } from 'resources/constants';
import { debugConsole } from 'resources/utils';
import { QuoteContext } from '_Broker/pages/Quote';

import FetchReferralId from './FetchReferralId';

const PanelWrap = styled(RoundedDiv)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${colours.product_loan_bg};
  margin-top: 20px;
  padding: 0;

  & div {
    padding: 15px 25px;
    width: 50%;

    label{
      color: ${colours.sub_text_copy};
    }

    @media ${media.tablet} {
      width: 25%;
    }
  }
`
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  line-height: 40px;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid ${colours.product_loan_border};

  @media ${media.tablet} {
    font-size: 20px;

    & span,
    & button {
      font-size: 16px;
    }
  }
`
const TitleSpan = styled.p`
  margin: 0;

  @media ${media.tablet} {
    width: 50%;
  }
  @media ${media.desktop} {
    width: 60%;
  }
`
const Rate = styled.div`
  max-width: 144px;

  & label{
    color: ${colours.product_loan_bg}!important;
  }

  & span {
    color: white;
    background-color: ${colours.header_footer};
    border-radius: 6px;
    padding: 3px 6px;
  }

  @media ${media.tablet} {
    background-color: ${colours.header_footer};
    color: white;
    border-bottom-left-radius: 8px;

    & span {
      padding: 0;
    }
  }
`
const Figure = styled.span`
  font-family: 'RNHouseSansBold';
  font-size: 22px;
`
const LoanLink = styled(TextLink)`
  line-height: 40px;

  @media ${media.tablet} {
    padding: 0 25px !important;
  }
`
const PanelButton = styled(Button)`
  margin-bottom: 0;
  min-width: auto;
  width: 100%;
  padding: 5px 20px;

  @media ${media.tablet} {
    width: auto;
  }
`

const Product = ({ product, handleOpen, handleStateChange, handleStageChange, quote, loanAmount, loanTerm }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [fetchRefer, setFetchRefer] = useState(false);
  const context = useContext(QuoteContext);
  const loanTotal =  Number(context['loan.amount'].value);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize) }
  }, [])

  const handleRefer = useCallback(() => {
    debugConsole('refer product', product);
    debugConsole('refer quote', quote);

    trackButtonClick('Button', 'Refer Loan');

    setFetchRefer(true);

    handleStateChange([
      {choiceId: 'id.product', choiceVal: product.id},
      {choiceId: 'id.product.name', choiceVal: product.name},
      {choiceId: 'id.product.rate', choiceVal: product.rate},
      {choiceId: 'id.product.monthlyCost', choiceVal: product.monthlyCost},
      {choiceId: 'id.product.arrangementFee', choiceVal: product.arrangementFee},
      {choiceId: 'id.product.earlyRepaymentFee', choiceVal: product.earlyRepaymentFee},
      {choiceId: 'id.quote', choiceVal: quote}
    ]);
  // eslint-disable-next-line
  }, [product.id, quote] );

  return (
    <PanelWrap>
      <Title>
        <TitleSpan>{product.name}</TitleSpan>
        {width >= media.tabletWidth && <>
          {loanAmount ? <span style={{lineHeight: '20px'}}>Loan amount<strong><br/>{formatter.format(loanAmount)}</strong></span> : <LoanLink onClick={() => {handleOpen(product.id); trackButtonClick('Button', 'Full loan details');}} style={{ width: width >= 1000 ? '25%' : '31%', textAlign: 'right' }}>Full loan details</LoanLink>}
          {loanTerm ? <span style={{lineHeight: '20px'}}>Loan term<strong><br/>{loanTerm} years</strong></span> : <PanelButton onClick={handleRefer}>Refer loan</PanelButton>}
        </>}
      </Title>
      <Rate>
        <label>Interest rate</label>
        <Figure>{product.rate}%</Figure>
      </Rate>
      <div>
        <label>Monthly repayments</label>
        <Figure>{formatter.format(product.monthlyCost)}</Figure>
      </div>
      <div>
        <label>Arrangement fee</label>
        <Figure>{!product.arrangementFee ? 'None' : formatter.format(product.arrangementFee * loanTotal / 100)}</Figure>
      </div>
      <div>
        <label>Early repayment fee</label>
        <Figure>{!product.earlyRepaymentFee ? 'None' : formatter.format(product.earlyRepaymentFee)}</Figure>
      </div>
      {width < media.tabletWidth && <>
        {loanAmount ? <div>Loan amount <strong>{formatter.format(loanAmount)}</strong></div> : <LoanLink onClick={() => handleOpen(product.id)}>Full loan details</LoanLink>}
        {loanTerm ? <div>Loan term <strong>{loanTerm} years</strong></div> : <div><PanelButton onClick={handleRefer}>Refer loan</PanelButton></div>}
      </>}
      {fetchRefer && <FetchReferralId product={product.id} quote={quote} handleStateChange={handleStateChange} handleStageChange={handleStageChange}  />}
    </PanelWrap>
  )
}

export default Product;
