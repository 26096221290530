import React, { useState, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { useFetch } from 'resources/hooks';
import { Button, media, Input, colours } from 'resources/global-styles';
import Warning from '../structure/Warning';

const Wrapper = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;

    & div {
      width: 100%;
      border-top: 1px solid ${colours.mediumGrey};
      padding: 10px 0;
      font-weight: bold;
    }
    & label {
      line-height: 55px;
      width: 100%;
    }
    & input {
      margin-right: 20%;
    }
  }
`

const SaveData = ({ type, auth, params }) => {
  var url = type === 'Lombard Funding Costs' ? 'lombard/FundingCosts' : type.replace(' ','');
  const {loading, error, data } = useFetch(url, true, params, {
    Authorization: `Bearer ${auth}`,
  });

  const labelText = type === 'Base Configs'
      ? 'Natwest Base Rate and UIR Rate' 
      : type;
  if (!loading && !error && data) return <p>{labelText} have been saved</p>;
  if (!loading && error) {
    if (error == "Unauthorised") {
      localStorage.removeItem("admin");
      return <Redirect to="/admin" />;
    }
    return <Warning>{error.title}</Warning>;
  }
  if (loading) return <p>[saving {labelText}]</p>;

  return null;
}

/**
 * sub-component - BCInput
 */
const BCInput = ({obj, dataArray, setDataArray}) => (
  <>
    <label>{obj.name}</label>
    <Input onChange={(evt) => setDataArray(dataArray.map(item => item.id === obj.id ? {...item, value: evt.target.value} : item ))} value={obj.value} maxLength='50' />
  </>
)

/**
 * sub-component - FCInput
 */
const FCInput = ({obj, dataArray, setDataArray}) => (
  <>
    <label>{obj.productName}</label>
    <Input onChange={(evt) => setDataArray(dataArray.map(item => item.id === obj.id ? {...item, rate: evt.target.value} : item ))} value={obj.rate} maxLength='50' />
  </>
)

/**
 * sub-component - RCInput
 */
const RCInput = ({obj, dataArray, setDataArray}) => (
  <>
    <div>Years from {obj.termInYearsRangeFrom} to {obj.termInYearsRangeTo}</div>
    <label>Unsecured:</label>
    <Input onChange={(evt) => setDataArray(dataArray.map(item => item.id === obj.id ? {...item, unsecuredRate: evt.target.value} : item ))} value={obj.unsecuredRate} maxLength='50' />
    <label>Secured:</label>
    <Input onChange={(evt) => setDataArray(dataArray.map(item => item.id === obj.id ? {...item, fullySecuredRate: evt.target.value} : item ))} value={obj.fullySecuredRate} maxLength='50' />
  </>
)

/**
 * sub-component - LombardFCInput
 */
const LombardFCInput = ({obj, dataArray, setDataArray}) => (
  <>
    <label>{obj.termInYears} year fixed rate loan</label>
    <Input onChange={(evt) => setDataArray(dataArray.map(item => item.id === obj.id ? {...item, rate: evt.target.value} : item ))} value={obj.rate} maxLength='50' />
  </>
)

/**
 * component - Form
 */
const Form = ({ type, auth }) => {
  const [dataArray, setDataArray] = useState([]);
  const [save, setSave] = useState(false);

  // var url = type === 'Lombard Funding Costs' ? 'lombard/FundingCosts' : type.replace(' ','');
  let url;
  switch (type) {
    case 'Funding Costs':
      url = 'fundingCosts';
      break;
    case 'Rate Cards':
      url = 'rateCards';
      break;
    case 'Lombard Funding Costs':
      url = 'lombard/FundingCosts';
      break;
    case 'Base Configs':
      url = 'baseConfigs';
      break;
    default:
  }
  
  const {loading, error, data } = useFetch(url, false, null, {
    Authorization: `Bearer ${auth}`,
  });

  // Probably due to token expiry, need to log in again
  if (!loading && error) {
    localStorage.removeItem('admin');
    return <Redirect to='/admin' />
  }

  if (loading) return <p>[loading {type}]</p>;

  if (data && !dataArray.length) setDataArray(data);

  if (save) {
    let params;
    switch (type) {
      case 'Funding Costs':
        params = {
          fundingCosts: dataArray.map(item => { return {...item, rate: isNaN(item.rate) ? 0 : parseFloat(item.rate)}})
        }
        break;
      case 'Rate Cards':
        params = {
          rateCards: dataArray.map(item => { return {...item, unsecuredRate: isNaN(item.unsecuredRate) ? 0 : parseFloat(item.unsecuredRate), fullySecuredRate: isNaN(item.fullySecuredRate) ? 0 : parseFloat(item.fullySecuredRate)}})
        }
        break;
      case 'Lombard Funding Costs':
        params = {
          lombardFundingCosts: dataArray.map(item => { return {...item, rate: isNaN(item.rate) ? 0 : parseFloat(item.rate)}})
        }
        break;
      case 'Base Configs':
        params = {
          baseConfigs: dataArray.map(item => { return { ...item } })
        }
        break;
      default:
    }
    
    if (params) return <SaveData dataArray={dataArray} type={type} auth={auth} params={params} />
  }
  // console.log(dataArray);
  if (dataArray.length) return (
    <Wrapper>
      {dataArray.map( obj => (
        <Fragment key={obj.id}>
          {type === 'Base Configs' && <BCInput obj={obj} dataArray={dataArray} setDataArray={setDataArray} />}
          {type === 'Funding Costs' && <FCInput obj={obj} dataArray={dataArray} setDataArray={setDataArray} />}
          {type === 'Rate Cards' && <RCInput obj={obj} dataArray={dataArray} setDataArray={setDataArray} />}
          {type === 'Lombard Funding Costs' && <LombardFCInput obj={obj} dataArray={dataArray} setDataArray={setDataArray} />}
        </Fragment>
      ))}
      <Button onClick={() => setSave(true)}>Save</Button>
    </Wrapper>
  )

  return null;
}

export default Form;